<template>
    <div class="header" id="header">
        <a href="#" class="header-logo">
            <img :src="logo" alt="logo" class="header-img">
        </a>
        <ul class="header-list" :class="{active: display}">
            <a class="header-link" @click="closeMenu" href="#header">Главная</a>
            <a class="header-link" @click="closeMenu" href="#program">Программы питания</a>
            <a class="header-link" @click="closeMenu" href="#delivery">Доставка</a>
            <a class="header-link" @click="closeMenu" href="#info">О нас</a>
            <div class="close" @click="closeMenu"></div>
        </ul>
        <div class="header-social">
            <a href="https://wa.me/79528278833" class="social">
                <img src="../assets/icons/whatsapp.svg" alt="telegram" class="icon whatsapp">
            </a>
            <a href="https://instagram.com/insighte.food" class="social">
                <img src="../assets/icons/instagram.svg" alt="inst" class="icon inst">
            </a>
        </div>
        <div class="hamburger" @click="openMenu">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
    import logo from '@/assets/img/logo.png'
    import { ref } from 'vue'

    export default{

        setup(){
            const display = ref(false)
            const openMenu = () => {
                display.value = true
            }
            const closeMenu = () => {
                display.value = false
            }
            return{
                logo,
                display, 
                openMenu,
                closeMenu
            }
        }
    }
</script>

<style scoped>
    .header{
        display: grid;
        align-items: center;
        grid-template-columns: 200px 1fr 1fr;
    }
    .header-img{
        width: 100%;
    }
    .header-list{
        position: relative;
        display: flex;
        gap: 20px;
    }
    .header-link{
        padding: 5px;
        font-weight: 700;
        font-size: 17px;
        color: #000;
        cursor: pointer;
        border-radius: 7px;
        transition: 0.2s;
    }
    .header-social{
        display: flex;
        justify-content: flex-end;
    }
    .header-link:hover{
        background: #000;
        color: #fff;
    }
    .hamburger{
        display: none;
        flex-direction: column;
        gap: 2px;
    }
    .hamburger div{
        width: 20px;
        height: 2px;
        background: #000;
    }
    .close{
        position: absolute;
        top: 30px;
        right: 20px;
        width: 24px;
        height: 24px;
        opacity: 0.2;
        cursor: pointer;
	&:hover {
		opacity: 1;
	}
}

    .close::before,
    .close::after {
        content: '';
        position: absolute;
        top: 10px;
        display: block;
        width: 24px;
        height: 3px;
        background: #ffffff;
        z-index: 111;
    }

    .close::before {
        transform: rotate(45deg);
    }

    .close::after {
        transform: rotate(-45deg);
    }
    @media(max-width: 768px){
        .hamburger{
            position: fixed;
            display: flex;
            padding: 20px;
            background: #dee1a7;
            border-radius: 20px;
            z-index: 50;
        }
        .header{
            justify-items: end;
        }
        .header-list{
            display: none;
        }
        .header-list.active{
            display: flex;
            position: fixed;
            padding-left: 10px;
            padding-top: 30px;
            top: 0;
            left: 0;
            flex-direction: column;
            width: 100%;
            height: 100vh;
            background: #000000cb;
            z-index: 100;
        }
        
        .header-link{
            color: #fff;
        }
        .header-social{
            justify-content: center;
        }
    }
    @media(max-width: 576px){
        .header{
        grid-template-columns: 143px 1fr 1fr;
        }
    }
</style>