<template>
    <div class="program" id="program">
        <h1 class="program-title">программы питания</h1>
        <div class="tabs">
            <ul class="tabs-header">
                <li class="tabs-weigth" :class="{active: activeTab === item.id}"
                    v-for="(item) in tabsArr"
                    :key="item.id"
                    @click="openNextTab(item)"   >
                    {{ item.calories }}</li>
            </ul>
            <div class="tabs-content" >
                <div class="tabs-block" v-for="(item, i) in tabsArr" :key="item.id" v-show="activeTab === i">
                    <div>
                        <div class="tabs-item">
                            <div class="tabs-title">{{ item.title}}</div>
                            <div class="tabs-text tabs-calories">{{ item.calories}}</div>
                            <div class="tabs-text tabs-medium">Средний БЖУ <span class="span span-green">{{ item.medium }}</span></div>
                        </div>
                        <div class="tabs-item">
                            <div class="tabs-title">Пример 1 дня меню:</div>
                            <div class="tabs-text tabs-menu">завтрак: {{ item.breakfast }}</div>
                            <div class="tabs-text tabs-menu" v-if="item.secondBreakfast">второй завтрак: {{ item.secondBreakfast }}</div>
                            <div class="tabs-text tabs-menu">обед: {{ item.lunch}}</div>
                            <div class="tabs-text tabs-menu" v-if="item.secondLunch">полдник: {{ item.secondLunch}}</div>
                            <div class="tabs-text tabs-menu">ужин: {{ item.dinner }}</div>
                            <div class="tabs-text tabs-menu" v-if="item.secondDinner">второй ужин: {{ item.secondDinner }}</div>
                        </div>
                        <div class="tabs-item" v-for="(price, ind) in item.price" :key="'id'+ind">
                            <div class="tabs-text tabs-price">пробный день <span class="span">{{price.test}}</span> рублей</div>
                            <div class="tabs-text tabs-price">1 день <span class="span">{{price.one}}</span> рублей</div>
                            <div class="tabs-text tabs-price">7 дней {{ price.seven }} рублей/день <span class="span">{{ price.sevenTotal }}</span> рублей</div>
                            <div class="tabs-text tabs-price">14 дней {{ price.fourteen }} рублей/день <span class="span">{{ price.fourteenTotal }}</span> рублей</div>
                            <div class="tabs-text tabs-price" v-if="price.thirty">30 дней {{price.thirty}} рублей/день <span class="span">{{ price.thirtyTotal }}</span> рублей</div>
                        </div>

                        <button class="btn program-btn" @click="openModal">Оформить подписку</button>

                        <div class="modal" v-show="showModal">
                            <form class="modal-content">
                                <div class="modal-title">Сделать заказ</div>
                                <div class="modal-subtitle">Оформить подписку на программу {{ item.calories }}</div>
                                <div class="modal-input">
                                    <div class="modal-subtitle">Ваше имя:</div>
                                    <input type="text" v-model="name" name="name" class="input">
                                </div>
                                <div class="modal-input">
                                    <div class="modal-subtitle">Номер телефона:</div>
                                    <input type="tel" v-model="phone" name="phone" class="input">
                                </div>
                                <button @click="onSumbitInTelegram(item.calories)" class="btn modal-btn">{{btnText}}</button>
                                <div class="modal-text" v-if="thanks">Спасибо за заказ</div>
                                <div class="modal-close" @click="closeModal"></div>
                            </form>
                        </div>
                    </div>
                    <div class="tabs-pic">
                        <img :src="item.img" alt="pic" class="tabs-img">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
    import { ref } from 'vue';
    import ration from '../assets/img/ration.jpg'
    import ration1 from '../assets/img/ration1.jpg'
    import ration2 from '../assets/img/ration2.jpg'
    import ration3 from '../assets/img/ration3.jpg'
    import ration4 from '../assets/img/ration4.jpg'

    export default{
        setup(){
            const TELEGRAM_BOT_TOKEN = '6448515073:AAHQZ4XNZkpHuhzq9viCotRee8iok0Re-40'
            const TELEGRAM_CHAT_ID = '@InsightFood'
            const API = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`
            const name = ref('')
            const phone = ref('+7')
            const thanks = ref(false)
            const btnText = ref('Оформить заказ')

            let activeTab = ref(0);

            let showModal = ref(false)

            const openModal = () => {
                showModal.value = true
            }

            const closeModal = () => {
                showModal.value = false
                thanks.value = false
            }

            const tabsArr = ref([
                {id: 0, calories: '800-900 ккал', title: 'Снижение веса', medium:'52/32/70', breakfast: 'Мексиканская кесадилья с цыплёнком',lunch: 'Пряный рис с с куриной грудкой, овощным миксом и сладким Чили', dinner: 'Салат «Цезарь с курицей»', price: [{test: '1100', one: '1300', seven: '1250',  sevenTotal: '8750', fourteen: '1200', fourteenTotal: '16800', thirty: '1100', thirtyTotal: '33000'}], img: ration},
                {id: 1, calories: '1100-1200 ккал', title: 'Снижение веса', medium:'80/55/85', breakfast: 'Мексиканская кесадилья с цыплёнком', secondBreakfast: 'Корзиночки на рисовой муке с творожным кремом и малиной' ,lunch: 'Пряный рис с с куриной грудкой, овощным миксом и сладким Чили', dinner: 'Салат «Цезарь с курицей»',  price: [{test: '1250', one: '1500', seven: '1450',  sevenTotal: '10150', fourteen: '1400', fourteenTotal: '19600', thirty: '1300', thirtyTotal: '39000'}], img: ration1},
                {id: 2, calories: '1400-1500 ккал', title: 'Снижение веса', medium:'100/55/105', breakfast: 'Мексиканская кесадилья с цыплёнком', secondBreakfast: 'Корзиночки на рисовой муке с творожным кремом и малиной', lunch: 'Пряный рис с с куриной грудкой, овощным миксом и сладким Чили', secondLunch: 'Форель с овощами по-нормандски', dinner: 'Салат «Цезарь с курицей»',  price: [{test: '1400', one: '1650', seven: '1600', sevenTotal: '11200', fourteen: '1550', fourteenTotal: '21700', thirty: '1450', thirtyTotal: '43500'}], img: ration1},
                {id: 3, calories: '1600-1700 ккал', title: 'Баланс', medium:'120/75/130', breakfast: 'Мексиканская кесадилья с цыплёнком', secondBreakfast: 'Корзиночки на рисовой муке с творожным кремом и малиной', lunch: 'Пряный рис с с куриной грудкой, овощным миксом и сладким Чили, Тыквенный крем-суп с креветкой', secondLunch: 'Форель с овощами по-нормандски', dinner: 'Салат «Цезарь с курицей»',  price: [{test: '1600',one: '1800', seven: '1750',  sevenTotal: '12250', fourteen: '1700', fourteenTotal: '23800', thirty: '1600', thirtyTotal: '48000'}], img: ration2},
                {id: 4, calories: '1800-1900 ккал', title: 'Баланс', medium:'130/80/145', breakfast: 'Кукурузная каша на кокосовом молоке с курагой', secondBreakfast: 'Корзиночки на рисовой муке с творожным кремом и малиной', lunch: 'Пряный рис с с куриной грудкой, овощным миксом и сладким Чили, Крем-суп из тыквы с креветками', secondLunch: 'Форель с овощами по-нормандски', dinner: 'Салат «Цезарь с курицей»',  price: [{test: '1700', one: '1950', seven: '1900',  sevenTotal: '13300', fourteen: '1850', fourteenTotal: '25900', thirty: '1750', thirtyTotal: '52500'}], img: ration2},
                {id: 5, calories: '2000-2200 ккал', title: 'Набор', medium:'150/90/170', breakfast: 'Кукурузная каша на кокосовом молоке с курагой', secondBreakfast: 'Корзиночки на рисовой муке с творожным кремом и малиной' ,lunch: 'Пряный рис с с куриной грудкой, овощным миксом и сладким Чили', secondLunch: 'Мексиканская кесадилья с цыплёнком', dinner: 'Форель с овощами по-нормандски', secondDinner: 'Салат «Цезарь с курицей»', price: [{test: '1850', one: '2050', seven: '2000',  sevenTotal: '14000', fourteen: '1950', fourteenTotal: '27300', thirty: '1850', thirtyTotal: '55500'}], img: ration3},
                {id: 6, calories: '2400-2500 ккал', title: 'Набор', medium:'165/105/220', breakfast: 'Кукурузная каша на кокосовом молоке с курагой', secondBreakfast: 'Корзиночки на рисовой муке с творожным кремом и малиной' ,lunch: 'Пряный рис с с куриной грудкой, овощным миксом и сладким Чили, Крем-суп из тыквы с креветкой', secondLunch: 'Мексиканская кесадилья с цыплёнком', dinner: 'Форель с овощами по-нормандски', secondDinner: 'Салат «Цезарь с курицей»',  price: [{test: '1900',one: '2200', seven: '2150',  sevenTotal: '15050', fourteen: '2100', fourteenTotal: '29400', thirty: '2000', thirtyTotal: '60000'}], img: ration3},
                {id: 7, calories: '1000-1200 ккал', title: 'Кето-диета', medium:'65/80/25', breakfast: 'Цветные спагетти из овощей с креветками в сливках', secondLunch: 'Мексиканская кесадилья с цыплёнком',  dinner: 'Салат с форелью слабой соли',  price: [{test: '1400', one: '1650', seven: '1600',  sevenTotal: '11200', fourteen: '1550', fourteenTotal: '21700'}], img: ration4},
            ])

            const openNextTab = (item) => {
                activeTab.value = item.id
            }
            
            async function onSumbitInTelegram (calories){
                event.preventDefault()
                
                if(name.value != ''){
                    let text = `Новый заказ\n ${calories}\n ${name.value}\n ${phone.value}`

                    try {
                        btnText.value = 'Loading...'

                        const response = await fetch(API, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                            },
                            body: JSON.stringify({
                                chat_id: TELEGRAM_CHAT_ID,
                                text,
                            })
                        });
                        if(response.ok){
                            thanks.value = true
                            name.value = ''
                            phone.value = '+7'
                        }
                        
                    } catch (error) {
                        console.log('-')
                    } finally{
                        btnText.value = 'Оформить заказ'
                    }
                }
            }

            return{
                name,
                phone,
                thanks,
                btnText,

                tabsArr,
                activeTab,
                showModal,
                openNextTab,
                openModal,
                closeModal,
                onSumbitInTelegram
            }
        }
    }
</script>

<style>
    .program{
        margin-top: 50px;
    }
    .program-title{
        font-weight: 700;
        font-size: 44px;
        color: #F7931E;
    }
    .program-btn{
        margin-top: 10px;
    }
    .tabs{
        margin-top: 30px;
    }
    .tabs-header{
        display: grid;
        grid-template-columns: repeat(8, 1fr);
    }
    .tabs-weigth{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        font-size: 16px;
        padding: 5px;
        border-radius: 18px 18px 0 0;
        cursor: pointer;
    }
    .tabs-weigth.active{
        background:#F2F2F2;
    }
    .tabs-content{
        padding: 24px;
        border: 4px solid #F2F2F2;
    }
    .tabs-block{
        display: grid;
        grid-template-columns: 600px 1fr;
    }
    .tabs-title{
        font-size: 30px;
    }
    .tabs-item{
        margin-top: 20px;
    }
    .tabs-pic{
        display: flex;
        justify-content: center;
    }
    .tabs-text{
        margin-top: 7px;
        font-size: 16px;
    }
    .tabs-img{
        max-width: 500px;
        max-height: 500px;
        border-radius: 10px;
    }
    .span{
        padding: 5px;
        border-radius: 10px;
        background: #000;
        color: #fff;
    }
    .span-green{
        background-color: #27bd93ee;
    }

    @media(max-width: 1200px){
        .tabs-block{
            grid-template-columns: 400px 1fr;
        }
    }
    @media(max-width: 768px){
        .program-title{
            text-align: center;
        }
        .tabs-header{
            grid-template-columns: none;
            grid-template-rows: repeat(8, 1fr);
        }
        .tabs-weigth{
            border-radius: 18px;
        }
        .tabs-block{
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        .tabs-pic{
            justify-content: start;
        }
    }
    @media(max-width: 576px){
        .program-title{
            font-size: 35px;
        }
        .tabs-content{
            padding: 14px;
        }
        .tabs-img{
            max-width: 264px;
            max-height: 300px;
        }
        .tabs-pic{
            justify-content: center;
        }
        .modal-content{
            width: 200px;
        }
    }

</style>