<template>
    <div class="delivery" id="delivery">
        <div class="delivery-title">ДОСТАВКА</div>
        <div class="delivery-subtitle">У нас ежедневная вечерня доставка по Сочи, Адлеру и Дагомысу. Доставка осуществляется с 18:00 до 22:00. Более точное время вы можете уточнить у менеджера.</div>
        <div class="delivery-maps">
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A8037fdf1201dea518293eee2f842409e87480dd0c8e35871793284eb530baea8&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
    export default{

    }
</script>

<style scoped>
    .delivery{
        margin-top: 30px;
    }
    .delivery-title{
        font-size: 44px;
        color: #000;
        font-weight: 700;
    }
    .delivery-subtitle{
        margin-top: 30px;
        font-size: 17px;
    }
    .delivery-maps{
        margin-top: 30px;
    }
    @media(max-width: 768px){
        .delivery-title{
            text-align: center;
        }
    }
    @media(max-width: 576px){
        .delivery-title{
            font-size: 35px;
        }
    }
</style>