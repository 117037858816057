import { ref } from "vue"

const showModalCallback = ref(false)
const thanks = ref(false)

const openModalCallback = () => {
    showModalCallback.value = true
}
const closeModalCallback = () => {
    showModalCallback.value = false
    thanks.value = false
}

export{
    showModalCallback,
    openModalCallback,
    closeModalCallback,
    thanks

}