<template>
    <div class="advantages">
        <div class="advantages-title">ПРЕИМУЩЕСТВА ПРАВИЛЬНОГО ПИТАНИЯ</div>
        <div class="advantages-block">
            <ul class="advantages-list">
                <li class="advantages-item" v-for="(item, i) in advantagesArr" :key="'id'+i">
                    <img :src="item.icon" alt="icon" class="advantages-icon">
                    <div class="advantages-text">{{ item.text }}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import body from '../assets/img/body.png'
    import heart from '../assets/img/heart.png'
    import shoes from '../assets/img/shoes.png'
    import smail from '../assets/img/smail.png'
    export default{
        components:{
        },
        setup(){
            const advantagesArr = [
                {text: 'Улучшение внешности', icon: body},
                {text: 'Нормализация настроения', icon: smail},
                {text: 'Дополнительная энергия', icon: shoes},
                {text: 'Снижение рисков', icon: heart}
            ]
            return{
                advantagesArr
            }
        }
    }
</script>

<style scoped>
    .advantages{
        margin-top: 50px;
    }
    .advantages-title{
        font-size: 44px;
        font-weight: 700;
        color: #93278F
    }
    .advantages-item{
        margin-top:20px;
        display: flex;
        width: 350px;
        padding: 15px;
        border-radius: 20px;
        opacity: 0.9;
        background: #fff;
    }
    .advantages-block{
        margin-top: 20px;
        padding: 50px;
        border-radius: 20px;
        background: url('../assets/img/polyana.jpg') center center/cover no-repeat;
    }
    .advantages-text{
        margin-left: 10px;
        font-size: 24px;
        font-weight: 300;
    }
    @media(max-width: 768px){
        .advantages-title{
            text-align: center;
        }
        .advantages-block{
            display: flex;
            justify-content: center;
        }
    }
    @media(max-width: 576px){
        .advantages-title{
            font-size: 30px;
        }
        .advantages-block{
            padding: 10px;
        }
        .advantages-item{
            padding: 6px;
            width: 280px;
        }
        .advantages-icon{
            width: 35px;
            height: 35px;
        }
        .advantages-text{
            font-size: 20px;
        }
    }
</style>